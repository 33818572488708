var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 d-none d-md-block"},[_c('carousel'),_c('h5',{staticClass:"Copyright-2021-Dat"},[_vm._v(" Copyright © 2021 Data Poem All rights reserved. Privacy and Legal Policies ")])],1),_c('div',{staticClass:"col-md-6 p-0"},[_c('div',{staticClass:"auth-wrapper py-0 py-md-5"},[_c('div',{staticClass:"auth-inner"},[_c('img',{staticClass:"Bitmap",attrs:{"src":require("../assets/bitmap.png"),"srcset":require("../assets/bitmap@2x.png") + " 2x, " + require("../assets/bitmap@3x.png") + " 3x"}}),_c('div',{staticClass:"Forgot-your-password- center-block"},[_vm._v(" Reset your password ")]),_c('b-form',{staticClass:"px-5",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"w-100 d-flex"},[_c('b-form-group',{staticClass:"w-100 p-2",attrs:{"id":"example-input-group-forgot"}},[_c('div',{staticClass:"d-flex w-100 p-0 px-md-4 row m-0"},[_c('span',{staticClass:"content-center inputIcon",class:{
                        invalid: _vm.$v.form.password.$error,
                        success:
                          !_vm.$v.form.password.$error &&
                          _vm.$v.form.password.$model == '',
                      }},[_c('img',{attrs:{"src":require("@/assets/lock-password-line.png"),"alt":"userIcon"}})]),_c('b-form-input',{staticClass:"c-form-input m-0 pr-0",staticStyle:{"width":"80%","margin-right":"-1px !important"},attrs:{"id":"example-input-1","name":"example-input-1","state":_vm.validateState('password'),"aria-describedby":"input-1-live-feedback","placeholder":"New password","type":_vm.showPassword ? 'text' : 'password'},model:{value:(_vm.$v.form.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.password, "$model", $$v)},expression:"$v.form.password.$model"}}),_c('span',{staticClass:"content-center inputIcon passwordIcon",class:{
                        invalid: _vm.$v.form.password.$error,
                        success:
                          !_vm.$v.form.password.$error &&
                          _vm.$v.form.password.$model == '',
                      }},[(!_vm.showPassword)?_c('img',{attrs:{"src":require("../assets/eye-close-line.svg"),"alt":""},on:{"click":_vm.togglePassword}}):_c('img',{attrs:{"src":require("../assets/eye-line.png"),"alt":""},on:{"click":_vm.togglePassword}})]),_c('b-form-invalid-feedback',{attrs:{"id":"input-1-live-feedback"}},[_vm._v("Required field min length 6.")])],1)])],1),_c('div',{staticClass:"w-100 d-flex mt-5"},[_c('b-form-group',{staticClass:"w-100 p-2",attrs:{"id":"example-input-group-2"}},[_c('div',{staticClass:"d-flex w-100 p-0 px-md-4 row m-0"},[_c('span',{staticClass:"content-center inputIcon",class:{
                        invalid: _vm.$v.form.repeatPassword.$error,
                        success:
                          !_vm.$v.form.repeatPassword.$error &&
                          _vm.$v.form.repeatPassword.$model == '',
                      }},[_c('img',{attrs:{"src":require("@/assets/lock-password-line.png"),"alt":"userIcon"}})]),_c('b-form-input',{staticClass:"c-form-input m-0 pr-0",staticStyle:{"width":"80%","margin-right":"-1px !important"},attrs:{"id":"example-input-2","name":"example-input-2","state":_vm.validateState('repeatPassword'),"aria-describedby":"input-2-live-feedback","placeholder":"Re-enter Password","type":_vm.showResetPassword ? 'text' : 'password'},model:{value:(_vm.$v.form.repeatPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.repeatPassword, "$model", $$v)},expression:"$v.form.repeatPassword.$model"}}),_c('span',{staticClass:"content-center inputIcon passwordIcon",class:{
                        invalid: _vm.$v.form.repeatPassword.$error,
                        success:
                          !_vm.$v.form.repeatPassword.$error &&
                          _vm.$v.form.repeatPassword.$model == '',
                      }},[(!_vm.showResetPassword)?_c('img',{attrs:{"src":require("../assets/eye-close-line.svg"),"alt":""},on:{"click":_vm.toggleResetPassword}}):_c('img',{attrs:{"src":require("../assets/eye-line.png"),"alt":""},on:{"click":_vm.toggleResetPassword}})]),_c('b-form-invalid-feedback',{attrs:{"id":"input-2-live-feedback"}},[_vm._v("Should be same as password.")])],1)])],1),_c('div',{staticClass:"text-right mt-4 px-4"},[_c('w-button',{staticClass:"mr-2",class:{ resetPasswordLink: _vm.isDisable },staticStyle:{"width":"45% !important"},attrs:{"buttonLoader":_vm.buttonLoader,"buttonText":'Reset',"isDisable":_vm.isDisable},on:{"buttonClicked":_vm.onSubmit}})],1)])],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }